// ============================
// GET IN TOUCH STYLES
// ============================


#get-in-touch {
    .get-in-touch__top-section {
        height: 600px;

        @include bp($medium) {
            height: auto;
        }

        .get-in-touch__contact-details {
            position: relative;
            background-image: url('../../../images/get-in-touch__details-bg.jpg');
            background-size: cover;
            @include justify-content(center);
            color: $c-text-light;

            .get-in-touch__overlay {
                @include absolute-fill;
                background-color: $c-image-overlay;
            }

            h2 {
                @include f-primary--bold;
                font-size: 18px;
                line-height: 22px;
                text-transform: uppercase;
            }

            p {
                margin: 0;
                font-size: 16px;
                line-height: 30px;
            }

            span {
                @include f-primary--bold;
                a {
                    color: $c-text-light;
                }
            }

            .contact-details__address,
            .contact-details__comms {
                margin-bottom: 20px;
            }

            .murphys-law {
                font-size: 12px;
                line-height: 19px;
            }


            @include bp($medium) {
                padding: 40px 0;
            }
        }

        .get-in-touch__contact-form {
            background-color: $light-grey;

            @include bp($medium) {
                padding: 40px 0;
            }
        }
    }

    .get-in-touch__map {
        margin: 0 auto;
    }
}
