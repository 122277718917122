// ============================
// GLOBAL VARIABLES
//    INDEX
//    - Colors
//        - Brand
//        - Backgrounds
//        - Typography
//        - Misc.
// ============================



// =======^^=============
// Colors
// ======================
    $white: #FFF;
    $off-white: #F9FAFA;
    $light-grey: #F0F0F0;
    $dark-grey: #383942;
    $mid-grey: #C9C9C9;
    $gold: #BA9749;
    $red: #C4484E;
    $dark-navy: #383942;
    $almost-black: #2B2B2B;


    // =======^^========
    // Brand
    // =================
    $c-brand--primary: $gold;
    $c-brand--secondary: $red;

    // =======^^========
    // Backgrounds
    // =================
    $c-main-bg: $white;
    $c-footer-bg: $dark-grey;
    $c-nav-bg: $dark-navy;
    $c-panel-bg: $light-grey;
    $c-property-search-bg: $light-grey;

    // =======^^========
    // Typography
    // =================
    $c-text-light: $off-white;
    $c-text-dark: $almost-black;
    $c-headings: $almost-black;
    $c-text-dark: $almost-black;
    $c-links: $red;

    // =======^^========
    // Misc.
    // =================
    $c-image-overlay: rgba(56, 57, 66, 0.74);
