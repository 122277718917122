// ===========================
// FORMS
//    INDEX
//    - Property Search Box
//    - Get In Touch form
//    - Google Recaptcha
//    - Mailchimp Signup
//    - Login Form
// ===========================


// Property Search Box

.search-box {
    background: $c-property-search-bg;
    height: 100px;

    @include bp($medium) {
        height: auto;
        @include align-items(flex-start);
    }

    .search-box__town,
    .search-box__sector,
    .search-box__buttons {
        height: 100%;
        padding: 20px;

        @include bp($medium) {
            width: 100%;
        }
    }

    .search-box__buttons {
        @include bp($medium) {
            @include flex-direction(row);
        }

        @include bp($small) {
            @include flex-direction(column);
        }
    }

    .search-box__town,
    .search-box__sector {
        @include align-items(flex-start);
    }

	select {
		@include appearance(none);
		outline: 0;
		border: 0;
		border-radius: 0;
		background-color: $off-white;
		background-image: url('../../../images/select-arrows.png');
		background-repeat: no-repeat;
		background-position: top right;
		height: 26px;
		width: 100%;
		padding: 0 5px;
		@include f-primary--light;
		font-size: 12px;
		color: $c-text-dark;
		position: relative;

        @include bp($small) {
            font-size: 16px;
        }

		&::after {
			content: "";
			display: block;
			position: absolute;
			right: 0;
			width: 20px;
			height: 26px;
			background-color: $red;
		}

        &::-ms-expand {
            display: none;
        }
	}

    label {
        @include f-primary--medium;
        font-size: 14px;
        color: $c-headings;
        line-height: 26px;
        text-transform: uppercase;
        margin: 0;
    }

	button {
		background-color: $red;
		padding: 10px 40px;
		margin-right: 20px;
		@include f-primary--medium;
		font-size: 12px;
		color: $off-white;
		line-height: 12px;
		text-transform: uppercase;

        @include bp($small) {
            width: 100%;
            margin: 0 0 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
	}
}


// Get in touch

.get-in-touch-form {
    h2 {
        margin-bottom: 40px;
    }
    .form__row {
        margin-bottom: 40px;
        @include flex-direction(row);

        @include bp-minmax($medium, 975px) {
            @include flex-direction(column);
            margin-bottom: 0;
        }

        @include bp($small) {
            @include flex-direction(column);
            margin-bottom: 0;
        }
    }

    .form__field {
        margin-right: 20px;

        &:nth-child(2n) {
            margin-right: 0;
        }

        @include bp-minmax($medium, 975px) {
            margin-right: 0;
            margin-bottom: 20px;
        }

        @include bp($small) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    input {
        @include appearance(none);
        outline: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid #979797;
        border-radius: 0;
        background: 0;
        width: 100%;

        &:focus {
            border-bottom: 2px solid $red;
        }

        &.business-name {
            @include bp($medium) {
                margin-bottom: 20px;
            }
        }
    }

    textarea {
        resize: none;
        height: 75px;
        @include appearance(none);
        background: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid #979797;
        border-radius: 0;
        outline: 0;
        width: 100%;
        margin-bottom: 20px;

        &:focus {
            border-bottom: 2px solid $red;
        }
    }

    button {
        background-color: $red;
        width: 148px;
        height: 45px;
        color: $c-text-light;
        text-transform: uppercase;
        @include f-primary--medium
        font-size: 14px;
        line-height: 45px;

        &:active {
            background-color: darken($red, 10%);
        }
    }

    .g-recaptcha {
        margin-bottom: 20px;
    }
}

.success-copy {
    text-align: center;
}



// Google Recaptcha v.2 Responsive code
@media screen and (max-height: 575px) {
	#rc-imageselect,
	.g-recaptcha {
		@include transform(scale(0.80));
		@include transform-origin(0 0);
	}
}


// Mailchimp Signup

#mailchimp {
    background-color: $red;

    .mailchimp-email {
        @include appearance(none);
        outline: 0;
        border: 0;
        border-radius: 0;
        width: 350px;
        height: 45px;
        padding-left: 10px;
        margin-right: 20px;

        @include bp($medium) {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .mailchimp-submit {
        width: 148px;
        height: 45px;
        background: #383942;
        border-radius: 1px;
        color: $c-text-light;
        text-transform: uppercase;
        @include f-primary--medium;
        font-size: 14px;
        vertical-align: top;

        @include bp($medium) {
            width: 100%;
        }
    }
}


// Login form


.login-form__container {
    .murphy-login-form {
        fieldset {
            border: 0;
            padding: 0;
            margin: 0;

            .login-form__row {
                margin-bottom: 20px;
            }

            .login-form__label {
                @include f-primary--bold;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            .login-form__input {
                input {
                    @include appearance(none);
                    outline: 0;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 2px solid #979797;
                    border-radius: 0;
                    background: 0;
                    display: inline-block;
                    padding: 10px;

                    &:focus {
                        border-bottom: 2px solid $red;
                    }
                }
            }

            .login-form__checkbox-label {
                display: inline-block;
                float: left;
                margin-bottom: 0;
                padding-left: 10px;
            }

            .login-form__checkbox {
                display: inline-block;
                float: left;
                input {
                    vertical-align: top;
                }
            }

            .login-form__button {
                background-color: $red;
                width: 148px;
                height: 45px;
                color: $c-text-light;
                text-transform: uppercase;
                @include f-primary--medium
                font-size: 14px;
                line-height: 45px;

                &:active {
                    background-color: darken($red, 10%);
                }
            }
        }
    }

    .login-form__forgots {
        padding: 20px 0;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: 10px;

                a {
                    text-decoration: none;
                    color: $c-text-dark;
                }
            }
        }
    }
}
