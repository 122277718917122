// ============================
// HEADER
// ============================

header {
    position: relative;
    margin: 0;
    padding-top: 70px;
    padding-bottom: 20px;
    width: 100%;
    height: auto;
    min-height: 788px;
    background: url('../../../images/hero-image.jpg') top center no-repeat;
    background-size: cover;

    @include bp($small) {
        padding: 40px 0;
    }

    @include bp($medium) {
        min-height: inherit;
        padding-bottom: 60px;
    }

    .header-overlay {
        @include absolute-fill;
        background-color: $c-image-overlay;
    }

    .murphy-logo {
        display: inline-block;
        width: 180px;
        height: 161px;
        margin-bottom: 67px;

        @include bp($medium) {
            width: 88px;
            height: 78px;
        }

        @include bp($small) {
            margin-bottom: 20px;
        }
    }


    .home-header-title {
        text-transform: uppercase;
        padding-right: 10%;
        margin-bottom: 40px;
        margin-top: 0;

        @include bp(870px) {
            font-size: 36px;
        }

        @include bp($small) {
            padding-right: 0;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 20px;
        }
    }

    .home-header-list {
        padding: 0;
        color: $white;
        list-style-position: inside;

        @include bp($small) {
            display: none;
        }

        .home-header-list__list-item {
            @include f-primary--bold;
            text-transform: uppercase;
            font-size: 22px;
            color: $c-text-light;
            line-height: 44px;
        }
    }

    &.small {
        min-height: inherit;

        @include bp($medium) {
            padding: 20px 0;
        }

        .murphy-logo {
            width: 100px;
            height: 89px;

            @include bp($small) {
                margin-bottom: 20px;
            }
        }

        .home-header-title {
            @include bp($small) {
                font-size: 24px;
                margin-bottom: 0;
            }
        }
    }
}
