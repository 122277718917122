// ============================
// HOMEPAGE STYLES
// ============================


// ========^^========
// Welcome Block
// ==================

.welcome-block {
    .welcome-text {
        padding-right: 20px;

        .no-flexbox &,
        .no-flexboxlegacy & {
            display: inline-block;
            float: left;
            width: 45%;
        }

        @include bp($medium) {
            padding: 0 0 40px 0;
        }

        @include bp($small) {
            padding: 0;
        }

        .welcome-image--mobile {
            display: none;

            @include bp($small) {
                display: block;
            }
        }
    }

    .welcome-image {
        padding-left: 20px;

        .no-flexbox &,
        .no-flexboxlegacy & {
            display: inline-block;
            float: right;
            width: 45%;
            height: 100%;
        }

        @include bp($medium) {
            padding: 0;
        }

        @include bp($small) {
            display: none;
        }
    }
}


// ========^^========
// Client Logos Block
// ==================

.client-logos-container {
    padding: 20px 0;

    .client-logo {
        margin-right: 20px;

        .no-flexbox &,
        .no-flexboxlegacy & {
            display: inline-block;
            width: 18%;
            text-align: center;
        }

        img {
            .no-flexbox &,
            .no-flexboxlegacy & {
                margin: 0 auto;
            }
        }


        &:last-of-type {
            margin-right: 0;
        }

        @include bp($medium) {
            margin-bottom: 20px;
        }
    }
}


// ========^^========
// Latest News Block
// ==================

.latest-news__container {
    @include bp(850px) {
        @include flex-direction(column);
        width: 75%;
    }

    @include bp($medium) {
        width: 100%;
    }
}

.latest-news-item {
    padding-right: 40px;

    .no-flexbox &,
    .no-flexboxlegacy & {
        display: inline-block;
        float: left;
        width: 30%;
    }

    &:last-of-type {
        padding-right: 0;
    }

    @include bp(850px) {
        padding-right: 0;
    }
}

.news-item__title {
    @include f-primary--bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}

.news-item__link {
    margin-bottom: 40px;
}

.news-item__body-text {
    padding-right: 0;
}


// ========^^======================================
// Director Level Service Block (working title...)
// ================================================

.dls-block {
    color: $c-text-light;

    .dls-block__text-container {
        padding-left: 40px;

        @include bp($medium) {
            padding-left: 0;
        }

        .dls__heading {
            @include f-primary--bold;
            font-size: 18px;
            text-transform: uppercase;
        }

        .dls__body-text {
            @include f-primary--regular;
            font-size: 12px;
        }
    }
}


// ========^^=====
// Twitter Feed
// ===============

.home__twitter-feed {
    height: 400px;
    position: relative;
    background: url('../../../images/twitter-feed-bg.jpg') no-repeat;
    background-size: cover;
    color: $c-text-light;

    @include bp($small) {
        height: auto;
    }

    .home__twitter-feed--overlay {
        @include absolute-fill;
        background-color: $c-image-overlay;
    }

    .twitter-content {
        @include bp($small) {
            text-align: center;
        }

        .twitter-text {
            font-size: 18px;
            line-height: 27px;
        }
        .twitter-time {
            font-size: 12px;
            color: $mid-grey;
        }
        a {
            color: $c-text-light;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .twitter-controls-container {
        @include display(flex);
        @include justify-content(center);
        @include align-items(center);

        .twitter-controls {
            display: inline-block;
        }

        .twitter-controls--left {
            padding-right: 40px;
        }
    }
}
