// ========^^========================================
// DASHBOARD
// ==================================================

.dashboard {
    .dashboard-grid {
        @include display(flex);
        @include justify-content(space-between);
        @include flex-wrap(wrap);

        .dashboard-grid__grid-item {
            @include flex(0 0 24%);
            height: 200px;
            margin-bottom: 20px;
            padding: 0;

            @include bp($medium) {
                @include flex-basis(49%);
            }

            @include bp($small) {
                @include flex-basis(100%);
            }

            .dashboard-item__link {
                @include display(flex);
                @include flex-direction(column);
                @include justify-content(center);
                width: 100%;
                height: 100%;
                padding: 20px;
                color: $c-text-dark;
                text-decoration: none;

                h3 {
                    text-transform: uppercase;
                    @include f-primary--bold;
                    font-size: 14px;
                    width: 100%;

                    i {
                        float: right;
                    }
                }
            }
        }
    }
}
