// ============================
// CONTAINERS
//    INDEX
//    - Wrappers
//    - Boxes
//        - Hero
//        - Overlay
//        - Inner page text
// ============================

.outer-wrapper {
    width: 90%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;

    @include bp($medium) {
        width: 100%;
        padding: 0 20px;
    }

    &.mobile-padding {
    	@include bp($medium) {
    		padding: 0 20px;
    	}
    }
}

.grid-wrapper {
    @extend .outer-wrapper;
    max-width: 1196px;
}


//==========^^===========
// BOX
//=======================

.box {
    padding: 20px;
}

.box--nopadding {
    padding: 0;
}

.box--extra-padding {
    padding: 20px 40px;
}

.box--relative {
    position: relative;
}


//==========^^===========
// SECTION
//=======================

section {
    width: 100%;
    background-color: $white;
    padding: 40px 0;

    @include bp($small) {
        padding: 20px 0;
    }

    &.section--dark {
        background-color: $dark-grey;
    }

    &.breadcrumbs {
        padding: 40px 0 0;

        @include bp($small) {
            padding: 20px 0 0;
        }
    }

    &.property-search-box {
        padding: 40px 0 0;
    }
}


//==========^^===========
// MAIN CONTENT
// Appears in most templates
//=======================

.main-content {
    padding-right: 15%;

    @include bp($medium) {
        padding-right: 0;
        margin-bottom: 40px;
    }
}


//==========^^===========
// PANEL
//    - SIDEBAR
//    - INFO
//    - LOGIN
//=======================

.panel {
    background: $c-panel-bg;
    border-top: 6px solid $red;
    padding: 20px 40px;
}

.sidebar-panel {
    @extend .panel;
    margin-bottom: 60px;

    @include bp($medium) {
        margin-bottom: 40px;
    }

    @include bp(1000px) {
        padding: 20px;
    }
}

.info-panel {
    @extend .panel;
    margin-bottom: 40px;
    padding: 20px;
}

.panel-login {
    padding: 20px 0;
    @include display(flex);
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
}
