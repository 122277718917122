// =================================
// TYPOGRAPHY
//    - Index
//        - Font stacks
//        - Elements
//            - Headings
//            - Body
// =================================

// =========^*^==============================
// FONT STACKS
// ==========================================

$f-primary: 'Poppins', sans-serif;

@mixin f-primary--regular {
    font-family: $f-primary;
    font-weight: 400;
}

@mixin f-primary--light {
    font-family: $f-primary;
    font-weight: 300;
}

@mixin f-primary--medium {
    font-family: $f-primary;
    font-weight: 500;
}

@mixin f-primary--bold {
    font-family: $f-primary;
    font-weight: 600;
}

// =========^*^==============================
// ELEMENTS
// =========================================



// =========^^=========
// HEADINGS
// ====================

.heading {
    @include f-primary--bold;
    font-size: 2em;
    font-size: 42px;
    color: $c-headings;
    line-height: 69px;
}

.box-title {
    @include f-primary--bold
    font-size: 18px;
    color: $c-headings;
    line-height: 28px;
    text-transform: uppercase;
}

.box-title--centered {
    text-align: center;
}

.box-title--light {
    @extend .box-title;
    color: $off-white;
}

.page-title {
    @extend .heading;
    color: $c-text-light;
    text-transform: uppercase;

    @include bp($medium) {
        font-size: 36px;
        line-height: 54px;
    }
}

.panel__title {
    @include f-primary--bold;
    color: $c-headings;
    text-transform: uppercase;
    margin-top: 0;
}

.sidebar-panel__title {
    @extend .panel__title;
    font-size: 14px;
    line-height: 20px;
}

.info-panel__title {
    @extend .panel__title;
    font-size: 20px;
    line-height: 20px;
}

// =========^^=========
// BODY
// ====================

.box-body {
    @include f-primary--regular;
    font-size: 12px;
    color: $c-text-dark;
    line-height: 24px;

    a {
        color: $red;
    }
}

.box-link {
    @include f-primary--bold;
    font-size: 12px;
    color: $c-links;
    line-height: 24px;
    text-transform: uppercase;
}

// =========^^=========
// PANELS
// ====================

.sidebar-panel {
    p {
        font-size: 12px;
        color: $c-text-dark;
        line-height: 19px;
    }

    a {
        color: $c-text-dark;
        @include f-primary--regular;
    }
}

.info-panel {
    p, a {
        font-size: 12px;
        line-height: 24px;

        i {
            padding-left: 5px;
        }
    }

    a {
        color: $c-text-dark;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        @include f-primary--bold;
    }
}
