// ============================
// BUTTONS
//    INDEX
//    - Base
//    - CTAs
//    - Links
// ============================


// ========^^=========
//    Base
// ===================

button {
    @include appearance(none);
    border: 0;
    outline: 0;
    background: 0;
}
