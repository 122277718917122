// ========^^========================================
// PROPERTY STYLES
//    Index
//    - Property Search Results
//    - Full Property Item Description
// ==================================================

.propertySearch {
    min-height: 350px;
    overflow: hidden;
}

.properties__list {
    padding-left: 20px;
    position: relative;
    overflow: hidden;
    @include align-items(center);

    @include bp($medium) {
        padding-left: 0;
    }
}

.properties__item {
    margin-right: 20px;
    margin-bottom: 20px;

    @include bp($medium) {
        margin-right: 0;
        margin-bottom: 40px;
    }

    img {
        height: auto;
    }

    .properties__item__info-panel {
        background-color: $c-panel-bg;
        text-align: center;
        width: 100%;
        padding: 20px;
        overflow: hidden;

        p {
            @include f-primary--bold;
            font-size: 14px;
            text-transform: uppercase;
            margin: 0 0 5px;

            &:last-of-type {
                margin-bottom: 20px;
            }
        }

        a {
            padding: 10px 20px;
            background-color: $red;
            color: $c-text-light;
            @include f-primary--bold;
            font-size: 12px;
            text-transform: uppercase;
            margin-top: 20px;
        }
    }
}

#no-results {
    text-align: center;
}

// ========^^==========================================
// FULL PROPERTY ITEM STYLES (property_item sublayout)
// ====================================================

.full-property-item {
    .main-content {
        @include bp(1024px) {
            padding-right: 5%;
        }

        @include bp($medium) {
            padding-right: 0;
        }
    }

    .full-property-item__title {
        @include f-primary--bold;
        font-size: 18px;
        line-height: 28px;
        text-transform: uppercase;
        margin-top: 0;
    }

    .full-property-item__image {
        margin-bottom: 40px;
    }

    .full-property-item__thumbnails {
        margin-bottom: 40px;

        & div {
            margin-right: 20px;
        }

        @include bp($medium) {
            @include flex-direction(row);
        }
    }

    .full-property-item__details {
        .full-property-item__details__block {
            margin-bottom: 40px;

            h4 {
                @include f-primary--medium;
                font-size: 14px;
                line-height: 24px;
                text-transform: uppercase;
            }

            p {
                font-size: 12px;
                line-height: 24px;
            }
        }
    }

    .full-property-item__featured-properties-container {
        @include align-items(baseline);

        // @include bp(1024px) {
        //     @include flex-direction(column);
        // }

        .full-property-item__featured-property {
            padding-right: 20px;
            @include justify-content(space-between);

            @include bp(1024px) {
                margin-bottom: 40px;
            }

            @include bp($medium) {
                display: block;
                padding-right: 0;
            }

            img {
                margin-bottom: 20px;
            }

            p {
                @include f-primary--bold;
                font-size: 14px;
                line-height: 28px;
                text-transform: uppercase;
                margin: 0;

                a {
                    color: $c-text-dark;
                    text-decoration: none;
                }
            }
        }
    }
}
