// ============================
// BASE STYLES
// ============================

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

body {
    background-color: $c-main-bg;
    color: $c-text-dark;
    position: relative;
    @include f-primary--regular;
}

main {
    padding: 0;
    margin: 0;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
}

// ============================
// ELEMENT STYLES
// ============================

.murphy-breadcrumbs {
    @include f-primary--regular;
    color: $almost-black;
    font-size: 12px;
    line-height: 26px;
    text-transform: uppercase;

    a.pathway {
        @include f-primary--light;
        margin: 0 10px;
        color: $almost-black;

        &:first-of-type {
            margin-left: 0;
        }
    }

    span.current-page {
        margin-left: 10px;
        @include f-primary--bold;
    }
}

.pagination {
    a,
    span {
        @include f-primary--medium;
        font-size: 14px;
        color: $almost-black;
        line-height: 30px;
        margin-right: 12px;
    }
    span {
        color: $red;
    }
    a:hover {
        color: $red;
    }
}
