// ============================
// ABOUT PAGE STYLES
// ============================

// ========^^=========
// BASE
// ===================


.about__main-content__copy-container {
    p,
    ul li {
        font-size: 12px;
        line-height: 24px;
    }

    ul {
        padding: 0;
        list-style-position: inside;
    }

    .first-paragraph {
        p {
            @include f-primary--medium;
        }
    }

    .about__main-content__sub-heading {
        @include f-primary--bold
        font-size: 20px;
        color: $c-headings;
        line-height: 28px;
        text-transform: uppercase;
    }

    .eamonn-murphy-image {
        float: left;
        padding: 0 20px 20px 0;

        @include bp($small) {
            float: none;
        }
    }
}

.about__sidebar {
    @include bp($medium) {
        display: none;
    }
}
