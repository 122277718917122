// ==================
// NAV
//    INDEX
//    - Base
//    - Button
// ==================

$nav-width: 250px;

// =======^^========
// BASE
// =================

nav {
    display: block;
    width: $nav-width;
    height: 100%;
    position: fixed;
    right: -$nav-width;
    z-index: 10;
    cursor: pointer;
    padding: 70px 20px 20px;
    background-color: $c-nav-bg;
    color: $c-text-light;
    overflow: scroll;
    @include transition(all 0.5s ease-in-out);

    &.is-active {
        @include transform(translateX(-$nav-width));
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    }

    @include bp($small) {
        padding-top: 40px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            padding-top: 15px;
            padding-bottom: 15px;
            position: relative;

            @include bp($medium, min) {
                &:hover {
                    a::after {
                        opacity: 1;
                    }
                }
            }

            a {
                display: block;
                @include f-primary--medium;
                font-size: 12px;
                color: $c-text-light;
                text-transform: uppercase;

                @include bp(769px, min) {
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        background-color: $red;
                        opacity: 0;
                    }
                }

                @include bp($medium) {
                    padding-left: 5px;
                    &:active {
                        background-color: $red;
                    }
                }
            }

            &.nav-telephone-link {
                a {
                    font-size: 16px;
                }
            }
        }
    }
}

// =======^^========
// BUTTON
// =================

.nav-toggle {
    position: fixed;
    width: 60px;
    height: 60px;
    right: 40px;
    top: 40px;
    z-index: 20;
    background-color: #27282F;
    cursor: pointer;

    .no-flexbox &,
    .no-flexboxlegacy {
        text-align: center;
        line-height: 78px;
    }

    @include bp($small) {
        right: 10px;
        top: 10px;
        width: 45px;
        height: 45px;
        @include flex-direction(row);
    }

    .nav-toggle-icon {
        display: inline-block;
        text-align: center;
        padding: 10px 35px 16px 0px;
        max-width: 100%;

        @include bp($small) {
            padding: 10px 25px 14px 0px;
        }

        span,
        span:before,
        span:after {
            cursor: pointer;
            border-radius: 1px;
            height: 5px;
            width: 35px;
            background: #dbdbdb;
            position: absolute;
            display: block;
            content: '';
            @include transition(all 0.2s ease-in-out);

            @include bp($small) {
                width: 25px;
            }
        }

        span:before {
            top: -10px;
        }

        span:after {
            bottom: -10px;
        }

        &.is-active {
            span {
                background-color: transparent;
            }

            span:before,
            span:after {
                top: 0;
            }

            span:before {
                @include transform(rotate(45deg));
            }

            span:after {
                @include transform(translateY(-10px) rotate(-45deg));
                top: 10px;
            }
        }
    }
}
