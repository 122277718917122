// ===============================
// RESPONSIVE ELEMENTS
//    INDEX
//    - Breakpoints
//    - Grid
// ===============================


// =====^^=========
// BREAKPOINTS
// ================

$small: 479px;
$medium: 768px;
$large: 1200px;


// ======^^======
// GRID
// ==============

$grid: ".grid";
$grid__item: ".grid__item";

$defaultGutter: 28px;


.wrap {
    max-width: 90%;
    margin: 0 auto;
}

#{$grid}{

    @extend .cf;
    padding: 0;
    list-style: none;

    #{$grid__item}{

        float: left;
    }

    &[class*="switched"]{
        > #{$grid__item}{

           float: right;
        }
    }

    /* eg no gutter */

    &.grid--1-12--ng {

        @include grid-cols(1, 0px);

        @include bp($medium){

            @include grid-cols(12, 0px);

        }
    }

    &.grid--1-12 {

        @include grid-cols(1, 0px);

        @include bp($medium){

            @include grid-cols(12);

        }
    }

    &.grid--1-12--lg {

        @include grid-cols(1, 0px);

        @include bp($small){

            @include grid-cols(12, $defaultGutter * 3);

        }
    }

    &.grid--1-6-12 {

        @include grid-cols(1, 0px);

        @include bp($medium) {

            @include grid-cols(6);
        }

        @include bp($large) {

            @include grid-cols(12);
        }

    }

    /* eg with default gutter */

    &.grid--1-8-12 {

        @include grid-cols(1, 0px);


        @include bp($medium) {

            @include grid-cols(8);
        }


        @include bp($large) {

            @include grid-cols(12);

        }

    }


    &.grid--1-2-3 {

        @include grid-cols(3, 0px);

        @include bp($medium) {

            @include grid-cols(2);
        }

        @include bp($small) {

            @include grid-cols(1);
        }

    }

    &.grid--homepage-property-search {

        @include grid-cols(3);

        @include bp(1000px) {
            @include grid-cols(2);
        }

        @include bp($medium) {
            @include grid-cols(1, 0px);
        }
    }
}
