// ============================
// FLEX
//    INDEX
//    - Container
//    - Item
//        - 2-cols
// ============================

.flex__container {
    @include display(flex);

    @include bp($medium) {
        @include flex-direction(column);
    }
}

.flex__container--col {
    @include display(flex);
    @include flex-direction(column);
}

.flex__item {
    @include flex(1);
}

.flex__item--2 {
    @include flex(2);
}

.flex__item--3 {
    @include flex(3);
}

.flex__centered {
    @include justify-content(center);
    @include align-items(center);
}

.flex__spaced {
    @include justify-content(space-between);
    @include align-items(center);
}

.flex__wrap {
    @include flex-wrap(wrap);
}
