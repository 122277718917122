// ============================
// FOOTER
// ============================

footer {
    background-color: $c-footer-bg;
    padding: 60px 0 20px;
    color: $c-text-light;

    .footer__container {
        padding-bottom: 20px;
        border-bottom: 2px solid rgba(249,250,250,0.50);

        .footer__column {
            padding-right: 40px;

            @include bp($medium) {
                @include flex-direction(row);
                margin-bottom: 20px;
                padding-right: 0;
            }

            .footer__logo {
                margin-bottom: 29px;
                width: 109px;
                height: 97px;
                max-width: 100%;
            }

            .footer__rics {
                width: 100px;
                height: 36px;
                margin: 20px 0;
            }

            .footer__heading {
                margin-top: 0;
                font-size: 18px;
                color: $c-text-light;
                line-height: 29px;
                text-transform: uppercase;
            }

            .footer__copy {
                @include f-primary--light;
                font-size: 12px;
                color: $c-text-light;
                line-height: 16px;
            }

            .footer__link {
                @include f-primary--light;
                display: block;
                text-decoration: none;
                color: $c-text-light;
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 20px;

                &:hover {
                    text-decoration: underline;
                }
            }

            .footer__address {
                margin-bottom: 20px;

                p {
                    @include f-primary--light;
                    font-size: 12px;
                    color: $c-text-light;
                    line-height: 14px;
                    margin: 0 0 10px;
                }
            }

            .footer__tel,
            .footer__email {
                color: $c-text-light;
                @include f-primary--bold;
                text-decoration: none;
            }
        }

        .footer__column--logo {
            p {
                width: 75%;

                @include bp($small) {
                    width: 100%;
                }
            }
        }

        .footer__column--links,
        .footer__column--news {
            @include bp($medium) {
                display: none;
            }
        }
    }

    .footer-lower {
        padding-top: 20px;

        p {
            font-family: Avenir, Arial, sans-serif;
            font-size: 10px;
            color: $c-text-light;
            line-height: 16px;
            margin: 0;
            padding: 0;
        }

        a {
            color: $c-text-light;
            font-size: 10px;
            padding-right: 10px;

            &:last-of-type {
                padding-right: 0;
            }
        }

        .footer-copyright {
            @include justify-content(flex-start);
        }

        .footer-website-by {
            @include justify-content(flex-end);
        }

        .footer-social-icons,
        .footer-copyright {
            @include bp($medium) {
                @include flex-direction(row);
                margin-bottom: 20px;
            }
        }

        .footer__elm-link {
            color: $red;
        }
    }
}
