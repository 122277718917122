// ============================
// NEWS LIST PAGE STYLES
//    Index
//        - News feeds
//        - Single articles
// ============================

// ========^^=========
// News Feeds
// ===================

// Styles affect news article listings on both the homepage and news listing page
// as well as full article layouts

.news-list {
    padding-right: 20px;

    .teaser-item {
        margin-bottom: 60px;
    }

    @include bp($medium) {
        padding-right: 0;
    }
}

.news-item {
    margin-bottom: 40px;
    border-bottom: 3px solid $off-white;

    h2 {
        @include f-primary--bold;
        font-size: 20px;
        color: $c-headings;
        line-height: 30px;
        text-transform: uppercase;
        margin-top: 0;
    }

    p {
        @include f-primary--regular;
        font-size: 12px;
        color: $c-text-dark;
        line-height: 24px;
        margin: 0;

        a {
            color: $red;
            text-transform: none;
            @include f-primary--regular;
        }

        &.news-item__read-more a {
            @include f-primary--bold;
            display: inline-block;
            font-size: 12px;
            color: $c-links;
            line-height: 24px;
            text-transform: uppercase;
            margin: 20px 0;
        }
    }



    img {
        margin-bottom: 40px;
    }

    @include bp($medium) {
        width: 100%;
        margin-bottom: 60px;
    }
}

.latest-news-block,
.latest-news-item {
    p {
        @include f-primary--regular;
        font-size: 12px;
        color: $c-text-dark;
        line-height: 24px;
    }

    a {
        @include f-primary--bold;
        display: inline-block;
        font-size: 12px;
        color: $c-links;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    img {
        margin-bottom: 20px;
    }

    @include bp($medium) {
        p {
            padding-right: 0;
        }

        img {
            padding-right: 0;
        }
    }
}

.latest-news-item {
    @include bp(850px) {
        margin-bottom: 40px;
        border-bottom: 3px solid $off-white;
    }
}

#news-item--full {
    .news-item {
        border-bottom: 0;
        padding-right: 15%;

        @include bp($medium) {
            padding-right: 0;
        }

        .news-item__info {
            margin-bottom: 20px;
        }

        .news-item__info__category {
            a {
                text-transform: uppercase;
            }
        }

        .news-item__image--full {
            margin-bottom: 40px;
        }

        .news-item__body-text {
            @include bp($medium) {
                padding-right: 0;
            }
        }

        a {
            text-transform: none;
            @include f-primary--regular;
        }
    }
}

.news-item__info {
    @include f-primary--bold;
    font-size: 12px;
    color: $c-text-dark;
    line-height: 15.8px;
    text-transform: uppercase;
    margin-bottom: 20px;

    .news-item__info__separator {
        content: "";
        height: 5px;
        width: 5px;
        border-radius: 100%;
        background-color: $c-text-dark;
        display: inline-block;
        line-height: 15px;
        margin: 2px 10px;
    }

    p {
        margin: 0;
    }

    .news-item__info__category {
        a {
            text-transform: uppercase;
        }
    }

    a {
        margin-bottom: 0;
    }
}

// Share box

.news-item__share-box {
    width: 80%;
    padding: 20px 0;
    border-top: 3px solid $off-white;
    border-bottom: 3px solid $off-white;
    @include align-items(center);

    @include bp($medium) {
        @include flex-direction(row);
        width: 100%;
        margin-bottom: 40px;
    }

    .news-item__share-box__title {
        color: #9B9B9B;
        margin-right: 20px;
        @include f-primary--regular;
        font-size: 12px;
        text-transform: uppercase;

        @include bp($small) {
            margin-right: 20px;
        }
    }

    .socialbuttons {
        margin-top: 0;

        @include bp($medium) {
            @include flex-direction(row);
        }

        @include bp($small) {
            width: 100%;
        }

        .socialbutton {
            width: 60px;
            -ms-flex-preferred-size: 60px;

            .twitter-share-button {
                display: inline-block;
                text-align: center;
                width: 40px;
                background-color: #55ACEE;
                border-radius: 3px;
                color: $white;
                font-size: 13px;
            }
        }
    }

    .news-item__share-box__link {
        color: $almost-black;
        margin-right: 40px;

        &:hover {
            color: $red;
        }
    }
}


// Sidebar

.news-list__sidebar {
    padding-top: 40px;

    @include bp($medium) {
        display: none;
    }
}
