// ============================
// SERVICES PAGE STYLES
//    Index
//
// ============================


.services-intro {
    margin-top: 0;
    text-transform: uppercase;
    @include f-primary--bold;
    color: $c-text-dark;
    font-size: 20px;
    line-height: 30px;
}

.primary-services-list {
    list-style-type: none;
    list-style-position: inside;
    padding: 20px 0;
    margin: 0;
    line-height: 24px;

    .teaser-item {
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }

        h2 {
            @include f-primary--bold;
            font-size: 16px;
            text-transform: uppercase;
        }

        p {
            font-size: 12px;
        }
    }
}

.secondary-services {
    p {
        font-size: 12px;
        line-height: 24px;
    }

    .secondary-services-list {
        margin: 0 20px 0 0;
        padding: 0;
        list-style-position: inside;
        font-size: 12px;
        line-height: 30px;
    }
}
