// ============================
// CLIENTS PAGE STYLES
// ============================


.clients__main-content__intro {
    margin-top: 0;
    margin-bottom: 40px;
}

.clients__list {
    list-style-type: none;
    padding: 0;
    list-style-position: inside;
}

.clients__list__list-item {
    margin-bottom: 60px;

    @include bp(975px) {
        @include flex-direction(column);
    }

    img {
        padding-right: 20px;
        padding-bottom: 20px;

        @include bp($medium) {
            padding-right: 0;
        }
    }

    h3 {
        margin-top: 0;
        margin-bottom: 10px;
        line-height: 18px;
    }

    p {
        @extend .box-body;
    }
}
